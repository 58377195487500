@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

* {
  font-size: 1.6rem;

  &:not(path) {
    @apply text-gray-600;
  }
}

:root {
  --toastify-toast-width: 50%;
}

.gradient {
  background: linear-gradient(90deg, #00568e 0%, #009ed0 100%);
}

a.active {
  @apply font-bold;
}
